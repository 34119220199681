@font-face {
  font-family: "Radikal1";
  src: local("Radikal"), url("./assets/fonts/Radikal.otf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "RadikalBold";
  src: local("Radikal"),
    url("./assets/fonts/Radikal Bold.otf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "RadikalMedium";
  src: local("RadikalMedium"),
    url("./assets/fonts/Radikal Medium.otf") format("truetype");
  font-weight: bold;
}
